<template>
  <div class="main_containter">
    <div class="body">
      <div class="main_heade">
        <video
          id="videoElement"
          :src="video.heji"
          controls
          width="100%"
          loop
          :poster="poster[checked]"
          autoplay
        />
        <div>
          <div class="title_css">
            <van-image
              class="logo_css"
              width="50"
              height="50"
              :src="poster.heji"
            />
            <div class="title_content_css">
              <div class="sub_title1_css">
                深圳火龙电子商务有限公司2023年校园招聘空中宣讲会
              </div>
              <!-- <div class="sub_title_css">第二场</div> -->
            </div>
          </div>
          <div class="video_css">
            <div
              @click="clickEvent('heji')"
              class="video_kuai"
              :class="checked == 'heji' ? 'on' : ''"
            >
              <van-image
                height="85"
                width="150"
                src="https://assets-img.hooloogostore.com/assets/xiaozhao/heji.jpg"
              />
              <img
                v-show="checked != 'heji'"
                class="v_icon"
                src="@/assets/img/index/loop@2x.png"
              />
              <palyingIcon
                v-show="checked == 'heji'"
                class="icon"
              ></palyingIcon>
            </div>
            <div
              @click="clickEvent('fangtan')"
              class="video_kuai"
              :class="checked == 'fangtan' ? 'on' : ''"
            >
              <van-image height="85" width="150" :src="poster.fangtan" />
              <img
                v-show="checked != 'fangtan'"
                class="v_icon"
                src="@/assets/img/index/loop@2x.png"
              />
              <palyingIcon
                v-show="checked == 'fangtan'"
                class="icon"
              ></palyingIcon>
            </div>
          </div>
        </div>
      </div>
      <div class="main_content">
        <van-tabs v-model="active" color="#BC8055" title-active-color="#BC8055">
          <van-tab title="关于我们">
            <div class="img_css">
              <van-image
                src="https://assets-img.hooloogostore.com/assets/xiaozhao/guanyuwomen1.jpg"
              />
            </div>
          </van-tab>
          <van-tab title="校招流程">
            <div class="img_css">
              <van-image
                src="https://assets-img.hooloogostore.com/assets/xiaozhao/xiaozhaoliucheng1.png"
              />·
            </div>
          </van-tab>
          <van-tab title="招聘Q&A">
            <div class="img_css">
              <van-image
                src="https://assets-img.hooloogostore.com/assets/xiaozhao/xiaozhaoQA1.png"
              />
            </div>
          </van-tab>
          <van-tab title="投递入口">
            <div class="img_css">
              <van-image
                src="https://assets-img.hooloogostore.com/assets/xiaozhao/toudirukou1.png"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;
const palyingIcon = () =>
  import("./components/palyingIcon").then((m) => m.default || m);
//方法导入处;

export default {
  components: {
    palyingIcon,
  },
  data() {
    return {
      active: 0,
      checked: "heji",
      poster: {
        heji: "https://assets-img.hooloogostore.com/assets/xiaozhao/heji.jpg",
        fangtan:
          "https://assets-img.hooloogostore.com/assets/xiaozhao/fangtan.jpg",
      },
      video: {
        heji:
          "https://assets-video.hooloogostore.com/azg-website/xiaozhao_heji2.mp4",
        fangtan:
          "https://assets-video.hooloogostore.com/azg-website/andy_fangtan2.mp4",
      },
    };
  },
  props: {
    //popupVisible: {
    //type: Boolean,
    //default: false
    //}
  },
  computed: {},
  methods: {
    clickEvent(type) {
      let value = this.video[type];
      console.log(type, value);
      this.checked = type;
      let videoElement = window.document.getElementById("videoElement");
      if (value != videoElement.src) {
        videoElement.src = value;
      }
      videoElement.play();
    },
  },
  mounted() {},
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
.main_containter {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: antiquewhite;
}
.body {
  max-width: 1080px;
  margin: auto;
}
.main_content {
  padding: 12px;
  padding-top: 6px;
  border-radius: 6px;
  background-color: white;
  margin-top: 12px;
  .img_css {
    margin-top: 24px;
  }
}
.main_heade {
  background-color: white;
  border-radius: 6px;
  .title_css {
    display: flex;
    padding: 12px;
    gap: 12px;
    .title_content_css {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      color: #454545;
      letter-spacing: 2px;
      // font-family: "宋体";
      gap: 2px;
      .sub_title1_css {
        text-align: left;
      }
      .sub_title_css {
        font-size: 14px;
        color: #818181;
      }
    }
    .logo_css {
      // border-radius: 100%;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
  .video_css {
    display: flex;
    gap: 12px;
    padding: 0 12px 8px 12px;
    .on {
      // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      border: solid 3px #fcb8ad;
    }
    .video_kuai {
      position: relative;
      display: flex;
      border-radius: 2px;
      overflow: hidden;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        position: absolute;
        // top: 0px;
        // right: 0px;
      }
      .v_icon {
        position: absolute;
        width: 50px;
      }
    }
  }
}
::v-deep .van-tab--active.van-tab--active {
}
::v-deep .van-tab__text {
  font-size: 16px;
}
</style>
